






























































































































































































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { CommonModule } from '@/store/modules/common'
import {
  checkUserBindWx,
  getUserInfoByUserId,
  applyDraw,
  drawBankCard,
  getBindInfo,
  saveBindInfo
} from '@/api'
import { Toast } from 'vant'
import {
  appFunc_callLogin_wechat,
  appFunc_RegisterLoginCb
} from '@/utils/bridge'
import activeIcon from '@/assets/checked_icon.png'
import inactiveIcon from '@/assets/uncheck_icon.png'
@Component({
  name: 'exchange-confirm',
  components: {}
})
export default class ExchangeConfirm extends Vue {
  presenTlist: any = {}
  activeIcon = activeIcon
  inactiveIcon = inactiveIcon
  //是否绑定微信
  bindWx = 0
  //头像
  headUrl = ''

  // 微信昵称
  wxNickName = ''
  realName = ''
  cardNum = ''
  loading = false

  showTipsPop = false
  notTips = false
  isSaveCardInfo = false

  //提现方式 1.Payoneer2.银行卡
  exchangeType = 1
  exchangeInfo = {
    bankAccountHolderName: '',
    bankCardNo: '',
    bankCny: '',
    bankCountry: '',
    bankIdNo: '',
    bankName: '',
    panyoneerAccount: '',
    panyoneerCny: '',
    payeeCityCounty: '',
    payeeCountry: '',
    payeeDetailAddress: '',
    payeeEmail: '',
    payeeMobile: '',
    payeeMobilePrefix: '',
    payeeStreetHouseNumber: '',
    payeeZipCode: ''
  }
  isBindPayonner = false
  isBindBankCard = false
  payonnerInfo = {
    panyoneerAccount: '',
    panyoneerCny: 'USD'
  }
  bankInfo: any = {
    bankAccountHolderName: '',
    bankCardNo: '',
    bankCny: '',
    bankCountry: '',
    bankIdNo: '',
    bankName: '',
    // panyoneerAccount: '',
    // panyoneerCny: '',
    payeeCityCounty: '',
    payeeCountry: '',
    payeeDetailAddress: '',
    payeeEmail: '',
    payeeMobile: '',
    payeeMobilePrefix: '',
    payeeStreetHouseNumber: '',
    payeeZipCode: ''
  }
  showPayonnerPop = false
  showBindCardPop = false
  bindState = 1
  payonnerBtnLoading = false
  bindCardBtnLoading = false
  CountryOptions = [
    { text: this.$t('土耳其'), value: 1 },
    { text: this.$t('阿曼'), value: 2 },
    { text: this.$t('巴林'), value: 3 },
    { text: this.$t('卡塔尔'), value: 4 },
    { text: this.$t('科威特'), value: 5 },
    { text: this.$t('沙特阿拉伯'), value: 6 },
    { text: this.$t('阿拉伯联合酋长国'), value: 7 }
  ]
  CountryMap: any = {
    1: this.$t('土耳其'),
    2: this.$t('阿曼'),
    3: this.$t('巴林'),
    4: this.$t('卡塔尔'),
    5: this.$t('科威特'),
    6: this.$t('沙特阿拉伯'),
    7: this.$t('阿拉伯联合酋长国')
  }
  CountryPrefixMap: any = {
    1: '+90',
    2: '+968',
    3: '+973',
    4: '+974',
    5: '+965',
    6: '+966',
    7: '+971'
  }
  showType1 = false
  showType2 = false
  showType3 = false
  showType4 = false
  prefixOptions = [
    {
      text: '+971',
      value: '+971'
    },
    {
      text: '+968',
      value: '+968'
    },
    {
      text: '+973',
      value: '+973'
    },
    {
      text: '+974',
      value: '+974'
    },
    {
      text: '+965',
      value: '+965'
    },
    {
      text: '+966',
      value: '+966'
    },
    {
      text: '+90',
      value: '+90'
    }
  ]
  isAgree = false
  get disabledBtn() {
    if (this.exchangeType == 1 && this.isBindPayonner) {
      return true
    }
    if (this.exchangeType == 2 && this.isBindBankCard) {
      return true
    }
    return false
  }
  get disableSteate2() {
    if (
      this.bankInfo.payeeMobilePrefix &&
      this.bankInfo.payeeMobile &&
      this.bankInfo.payeeStreetHouseNumber &&
      this.bankInfo.payeeDetailAddress &&
      this.bankInfo.payeeCityCounty &&
      this.bankInfo.payeeZipCode &&
      this.bankInfo.payeeCountry
    ) {
      return false
    }
    return true
  }
  get disableSteate3() {
    if (
      this.bankInfo.bankIdNo &&
      this.bankInfo.bankName &&
      this.bankInfo.bankAccountHolderName &&
      this.bankInfo.bankCardNo &&
      this.isAgree
    ) {
      return false
    }
    return true
  }
  created() {
    // 获取用户信息
    //this.getUserInfoByUserId()
    this.getData()
    // 获取当前语言

    this.presenTlist = this.$route.query.list
  }
  clickBandCard() {
    this.exchangeType = 2
    // if (this.presenTlist.money >= 50) {
    // } else {
    //   Toast(this.$t('银行不支持50美元以下的转款，超过50美元才能使用银行卡'))
    // }
  }
  getStateCache() {
    const tmp = localStorage.getItem('bindStateInfo1') as string
    if (tmp) {
      Object.assign(this.bankInfo, tmp)
    }
  }
  // 获取用户信息
  async getUserInfoByUserId() {
    const { data, code } = await getUserInfoByUserId({
      userId: this.$route.query.userId
    })
    if (code == 1001) {
      const { headUrl, wxNickName } = data
      this.headUrl = headUrl
      this.wxNickName = wxNickName
    }
  }
  async getData() {
    const { data, code } = await getBindInfo()
    if (code == 1001) {
      console.log(data)
      Object.assign(this.exchangeInfo, data)
      if (data.panyoneerAccount) {
        this.isBindPayonner = true
        Object.assign(this.payonnerInfo, data)
      }
      if (data.bankCardNo) {
        this.isBindBankCard = true
        Object.assign(this.bankInfo, data)
      }
    }
  }
  checkCardValid() {
    return true
  }

  beforeExchange() {
    if (!this.disabledBtn) return
    //先判断用户当天金豆兑换金额是否超过200（不包含200），若超过，则提交失败同时toast提示

    // const todayIncome = 150
    // if (todayIncome >= 200) {
    //   Toast(this.$t('每天最高只能提现200元，请明日再来提现'))
    //   return
    // }
    // Toast('提现中...')
    this.handelExchange()
  }
  saveAdnExchange() {
    localStorage.setItem('isSaveCardInfo', '1')
    localStorage.setItem('realName', this.realName)
    localStorage.setItem('cardNum', this.cardNum)
    localStorage.setItem('notTips', this.notTips ? '1' : '0')
    this.handelExchange()
  }
  saveState(type: number) {
    const obj: any = {
      payeeMobilePrefix: this.bankInfo.payeeMobilePrefix,
      payeeMobile: this.bankInfo.payeeMobile,
      payeeEmail: this.bankInfo.payeeEmail,
      payeeStreetHouseNumber: this.bankInfo.payeeStreetHouseNumber,
      payeeDetailAddress: this.bankInfo.payeeDetailAddress,
      payeeCityCounty: this.bankInfo.payeeCityCounty,
      payeeZipCode: this.bankInfo.payeeZipCode,
      payeeCountry: this.bankInfo.payeeCountry,
      bankCountry: this.bankInfo.bankCountry,
      bankCny: this.bankInfo.bankCny
    }
    localStorage.setItem('bindStateInfo1', JSON.stringify(obj))
    if (type == 1) {
      this.bindState = 2
    } else if (type == 2) {
      this.bindState = 3
    }
  }
  onlyExchange() {
    localStorage.setItem('isSaveCardInfo', '0')

    localStorage.setItem('notTips', this.notTips ? '1' : '0')
    this.handelExchange()
  }
  // 确认兑换
  async handelExchange() {
    this.loading = true
    // 提现-确认兑换
    const { aplus_queue }: any = window
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['click_to_bind_withdrawal', 'CLK']
    })
    const { code, message } = await drawBankCard({
      exchangeId: this.presenTlist.id,
      payType: this.exchangeType
    })
    if (code == 1001) {
      Toast(this.$t('兑换提交成功，在清单中可查看金豆兑换到账情况'))
      this.$router.push({
        path: '/exchange',
        query: {
          language: this.language
        }
      })
      this.loading = false
    } else {
      Toast(message)
      this.loading = false
    }
  }

  // 当前语言
  get language() {
    return this.$i18n.locale
  }

  // 切换语言
  getLanguage(language: string) {
    this.$i18n.locale = language
  }

  // 返回
  handelBack() {
    this.$router.back()
  }

  async submitPayonner() {
    if (this.payonnerInfo.panyoneerAccount === '') {
      return
    }
    if (this.payonnerBtnLoading) {
      return
    }
    this.bindCardBtnLoading = true
    const params = {
      panyoneerAccount: this.payonnerInfo.panyoneerAccount,
      panyoneerCny: this.payonnerInfo.panyoneerCny
    }
    try {
      const { data, code } = await saveBindInfo(params)
      if (code == 1001) {
        this.getData()
        this.$toast(this.$t('Panyoneer账户添加成功'))
        this.showPayonnerPop = false
        this.isBindPayonner = true
      }
    } finally {
      this.bindCardBtnLoading = false
    }
  }
  async submitBank() {
    // if (!this.isAgree) {
    //   this.$toast("请勾选'本人确认以上银行账户明细'")
    //   return
    // }
    if (this.disableSteate3) {
      return
    }
    if (this.payonnerBtnLoading) {
      return
    }
    this.bindCardBtnLoading = true
    const params = {
      ...this.bankInfo
    }
    try {
      const { data, code } = await saveBindInfo(params)
      if (code == 1001) {
        this.getData()
        this.$toast(this.$t('银行信息填写完成'))
        this.showBindCardPop = false
        localStorage.removeItem('bindStateInfo1')
        //this.isBindPayonner = true
      }
    } finally {
      this.bindCardBtnLoading = false
    }
  }
  changeShowType(type: number) {
    console.log('changeShowType', type)
    if (type === 1) {
      ;(this.$refs['drop1'] as any).toggle()

      this.showType1 = !this.showType1
      this.showType2 = false
      if (this.showType1) {
        ;(this.$refs['drop2'] as any).toggle(false)
      }
    } else if (type === 2) {
      ;(this.$refs['drop2'] as any).toggle()

      this.showType2 = !this.showType2
      this.showType1 = false
      if (this.showType2) {
        ;(this.$refs['drop1'] as any).toggle(false)
      }
    } else if (type === 3) {
      ;(this.$refs['drop3'] as any).toggle()
      this.showType3 = !this.showType3
      this.showType4 = false
      if (this.showType3) {
        ;(this.$refs['drop4'] as any).toggle(false)
      }
    } else if (type === 4) {
      ;(this.$refs['drop4'] as any).toggle()
      this.showType4 = !this.showType4
      this.showType3 = false
      if (this.showType4) {
        ;(this.$refs['drop3'] as any).toggle(false)
      }
    }
  }
  change1(e: any) {
    //showType1.value = false;
    console.log('change1', e)
    if (e != 1) {
      this.bankInfo.bankCny = 'USD'
    }
    ;(this.$refs['drop1'] as any).toggle(false)
    this.showType1 = !this.showType1
  }
  change2(e: any) {
    this.bankInfo.bankCny = e
    ;(this.$refs['drop2'] as any).toggle(false)
    this.showType2 = !this.showType2
  }
  change3(e: any) {
    ;(this.$refs['drop3'] as any).toggle(false)
    this.showType3 = !this.showType3
  }
  change4(e: any) {
    ;(this.$refs['drop4'] as any).toggle(false)
    this.showType4 = !this.showType4
  }
  nextBind() {
    if (!this.bankInfo.bankCny || !this.bankInfo.bankCny) {
      return
    }
    this.bankInfo.payeeMobilePrefix = this.CountryPrefixMap[
      this.bankInfo.bankCountry
    ]
    this.saveState(1)
  }
  nextBind2() {
    if (this.disableSteate2) {
      return
    }

    this.saveState(2)
  }
  handelBindClose() {
    this.showType1 = false
    this.showType2 = false
    this.showType3 = false
    this.showType4 = false
    ;(this.$refs['drop1'] as any).toggle(false)
    ;(this.$refs['drop2'] as any).toggle(false)
    ;(this.$refs['drop3'] as any).toggle(false)
    ;(this.$refs['drop4'] as any).toggle(false)
    this.bindState = 1
  }
}
